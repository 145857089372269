import React, {useState} from 'react'; 
import miffy from './miffy.jpg';
import './App.css';
import {useHistory} from "react-router-dom";
import TextField from "@mui/material/TextField"
export function Home () {
  let history = useHistory();
  const [password, setPassword] = useState(null);
  const [error, setError] = useState(false);
  function validate() {
    if (password === "beesbees69") {
      setError(false)
      history.push("/Letter");
    }
    else {
      setError(true)
    }
  }
  
  return (
   
    <div >
      <header className="App-header">
        <h1 style={{color: "#f3b3cc"}}>hi!</h1>
        <img width={210} src={miffy} alt="altie"/>
        
        <h3 style={{color: "#f3b3cc"}}>password</h3>
        <TextField onChange={(event) => {setPassword(event.currentTarget.value)}} error={error} type="password"/>
      
          <div onClick={() => validate()} style={{marginLeft: 230}}>
          <h4 style={{color: "#ffde59"}}>login &lt;3 </h4>
          </div>
   
      </header>
    </div>
  );
}
