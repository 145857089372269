import React from 'react'; 
import bun from "./bun.gif";
export function Bunny() {
    return (
        <div> 
        <div style={{display: "flex", justifyContent: "center"}}>
          <img width={330} src={bun} alt='bunni' />
        </div> 
        <div style={{display: "flex", justifyContent: "center"}}>
        <h3 style={{color: "#f3b3cc"}}>yayyayayayay</h3>
        </div>
        </div>
    );

}